// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-default-general-icon-close-name: 'sprite-default-general_icon-close';
$sprite-default-general-icon-close-x: 191px;
$sprite-default-general-icon-close-y: 0px;
$sprite-default-general-icon-close-offset-x: -191px;
$sprite-default-general-icon-close-offset-y: 0px;
$sprite-default-general-icon-close-width: 11px;
$sprite-default-general-icon-close-height: 11px;
$sprite-default-general-icon-close-total-width: 202px;
$sprite-default-general-icon-close-total-height: 147px;
$sprite-default-general-icon-close-image: 'sprite-default-general-1561385307481.png';
$sprite-default-general-icon-close: (191px, 0px, -191px, 0px, 11px, 11px, 202px, 147px, 'sprite-default-general-1561385307481.png', 'sprite-default-general_icon-close', );
$sprite-default-general-icon-refresh-name: 'sprite-default-general_icon-refresh';
$sprite-default-general-icon-refresh-x: 172px;
$sprite-default-general-icon-refresh-y: 11px;
$sprite-default-general-icon-refresh-offset-x: -172px;
$sprite-default-general-icon-refresh-offset-y: -11px;
$sprite-default-general-icon-refresh-width: 19px;
$sprite-default-general-icon-refresh-height: 20px;
$sprite-default-general-icon-refresh-total-width: 202px;
$sprite-default-general-icon-refresh-total-height: 147px;
$sprite-default-general-icon-refresh-image: 'sprite-default-general-1561385307481.png';
$sprite-default-general-icon-refresh: (172px, 11px, -172px, -11px, 19px, 20px, 202px, 147px, 'sprite-default-general-1561385307481.png', 'sprite-default-general_icon-refresh', );
$sprite-default-general-ideal-name: 'sprite-default-general_ideal';
$sprite-default-general-ideal-x: 135px;
$sprite-default-general-ideal-y: 31px;
$sprite-default-general-ideal-offset-x: -135px;
$sprite-default-general-ideal-offset-y: -31px;
$sprite-default-general-ideal-width: 37px;
$sprite-default-general-ideal-height: 32px;
$sprite-default-general-ideal-total-width: 202px;
$sprite-default-general-ideal-total-height: 147px;
$sprite-default-general-ideal-image: 'sprite-default-general-1561385307481.png';
$sprite-default-general-ideal: (135px, 31px, -135px, -31px, 37px, 32px, 202px, 147px, 'sprite-default-general-1561385307481.png', 'sprite-default-general_ideal', );
$sprite-default-general-mastercard-name: 'sprite-default-general_mastercard';
$sprite-default-general-mastercard-x: 90px;
$sprite-default-general-mastercard-y: 63px;
$sprite-default-general-mastercard-offset-x: -90px;
$sprite-default-general-mastercard-offset-y: -63px;
$sprite-default-general-mastercard-width: 45px;
$sprite-default-general-mastercard-height: 28px;
$sprite-default-general-mastercard-total-width: 202px;
$sprite-default-general-mastercard-total-height: 147px;
$sprite-default-general-mastercard-image: 'sprite-default-general-1561385307481.png';
$sprite-default-general-mastercard: (90px, 63px, -90px, -63px, 45px, 28px, 202px, 147px, 'sprite-default-general-1561385307481.png', 'sprite-default-general_mastercard', );
$sprite-default-general-paypal-name: 'sprite-default-general_paypal';
$sprite-default-general-paypal-x: 45px;
$sprite-default-general-paypal-y: 91px;
$sprite-default-general-paypal-offset-x: -45px;
$sprite-default-general-paypal-offset-y: -91px;
$sprite-default-general-paypal-width: 45px;
$sprite-default-general-paypal-height: 28px;
$sprite-default-general-paypal-total-width: 202px;
$sprite-default-general-paypal-total-height: 147px;
$sprite-default-general-paypal-image: 'sprite-default-general-1561385307481.png';
$sprite-default-general-paypal: (45px, 91px, -45px, -91px, 45px, 28px, 202px, 147px, 'sprite-default-general-1561385307481.png', 'sprite-default-general_paypal', );
$sprite-default-general-visa-name: 'sprite-default-general_visa';
$sprite-default-general-visa-x: 0px;
$sprite-default-general-visa-y: 119px;
$sprite-default-general-visa-offset-x: 0px;
$sprite-default-general-visa-offset-y: -119px;
$sprite-default-general-visa-width: 45px;
$sprite-default-general-visa-height: 28px;
$sprite-default-general-visa-total-width: 202px;
$sprite-default-general-visa-total-height: 147px;
$sprite-default-general-visa-image: 'sprite-default-general-1561385307481.png';
$sprite-default-general-visa: (0px, 119px, 0px, -119px, 45px, 28px, 202px, 147px, 'sprite-default-general-1561385307481.png', 'sprite-default-general_visa', );
$spritesheet-width: 202px;
$spritesheet-height: 147px;
$spritesheet-image: 'sprite-default-general-1561385307481.png';
$spritesheet-sprites: ($sprite-default-general-icon-close, $sprite-default-general-icon-refresh, $sprite-default-general-ideal, $sprite-default-general-mastercard, $sprite-default-general-paypal, $sprite-default-general-visa, );
$spritesheet: (202px, 147px, 'sprite-default-general-1561385307481.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
