/*
* 	Owl Carousel Owl Demo Theme
*	v1.3.3
*/

.owl-theme .owl-controls {
    margin-top: 10px;
    text-align: center;
    .owl-buttons div {
        color: #FFF;
        display: inline-block;
        zoom: 1;
        *display: inline;
        /*IE7 life-saver */
        margin: 5px;
        padding: 3px 10px;
        font-size: 12px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background: #869791;
        filter: Alpha(Opacity = 50);
        /*IE7 fix*/
        opacity: 0.5;
    }
    &.clickable .owl-buttons div:hover {
        filter: Alpha(Opacity = 100);
        /*IE7 fix*/
        opacity: 1;
        text-decoration: none;
    }
    .owl-page {
        display: inline-block;
        zoom: 1;
        *display: inline;
        /*IE7 life-saver */
        span {
            display: block;
            width: 12px;
            height: 12px;
            margin: 5px 7px;
            filter: Alpha(Opacity = 50);
            /*IE7 fix*/
            opacity: 0.5;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            background: #869791;
        }
        &.active span {
            filter: Alpha(Opacity = 100);
            /*IE7 fix*/
            opacity: 1;
        }
    }
    &.clickable .owl-page:hover span {
        filter: Alpha(Opacity = 100);
        /*IE7 fix*/
        opacity: 1;
    }
    .owl-page span.owl-numbers {
        height: auto;
        width: auto;
        color: #FFF;
        padding: 2px 10px;
        font-size: 12px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
    }
}

/* Styling Next and Prev buttons */

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */

/* Styling Pagination*/

/* If PaginationNumbers is true */

/* preloading images */

.owl-item.loading {
    min-height: 150px;
    background: url(AjaxLoader.gif) no-repeat center center;
}