//Main slider
.main-slider {
    position: relative;

    &__control-left,
    &__control-right {
        position: absolute;
        height: 30px;
        width: 30px;
        background: $c-black;
        z-index: 1;
        border: 0;
        transition: $default-transition-duration;
        top: calc(50% - 15px);
        border-radius: 3px;

        i.fa {
            font-size: 18px;
            height: 30px;
            width: 30px;
            line-height: 32px;
            text-align: center;
            transition: $default-transition-duration;
            color: $c-white;
        }
    }

    &__control-left {
        left: -15px;
    }

    &__control-right {
        right: -15px;
    }

    .item {
        height: 185px;
        text-align: center;

        img {
            height: 185px;
            width: auto;
        }

        .content {
            position: absolute;
            width: 100%;
            max-width: 1200px;
            z-index: 9999;
            bottom: 40px;
            left: 0;
            right: 0;
            margin: 0 auto;
            background: rgba(0, 0, 0, 0.5);

            h2, h3, h4 {
                font-weight: bold;
                margin-top: 10px;
                margin-bottom: 0;
                font-size: 18px;
                color: $c-white;
            }

            p {
                font-size: 16px;
                margin-top: 0;
                margin-bottom: 0;
                color: $c-white;
            }

            .button {
                margin-bottom: -20px;

                &:after {
                    @include fa-icon();
                    content: $fa-var-chevron-right;
                    font-size: 14px;
                    padding-left: 10px;
                }
            }
        }
    }
}

.home {
    &-container {
        padding: 0 20px;
    }

    &-top {

    }

    &-slider {
        margin: 20px 0;
    }

    &-menu {
        width: 100%;
        padding: 0 0 20px;
        z-index: 1;

        #nav-home {
            border-top: 1px solid $c-fieldset-border;
            border-bottom: 1px solid $c-fieldset-border;

            li.level1 a {
                border-bottom: 1px solid $c-module-border;
            }

            // Increase clickable area of anchors for small viewports
            a.level0,
            a {
                line-height: 30px;
            }

            // Hide all sub-menus
            li.level0 ul {
                display: none;
            }

            li.level0 {
                > ul {
                    display: block;
                    position: relative;
                    top: 0;
                    opacity: 1;
                    visibility: visible;
                    z-index: 99;

                    ul {
                        display: none;
                    }
                }

                .level1 > a {
                    font-weight: bold;
                }

                > a.level0,
                li.view-all,
                &.last,
                .nav-2-1,
                .nav-2-2,
                .nav-2-3,
                .nav-2-7,
                .nav-2-8,
                .nav-2-9,
                .nav-2-11 {
                    display: none;
                }

                &.nav-4 {
                    > a.level0 {
                        display: block;
                        text-transform: none;
                        font-weight: bold;

                    }

                    > ul {
                        display: none;
                    }
                }

            }

            li {
                padding-left: 0;

                a {
                    padding: 0 10px;
                    position: relative;
                    display: block;
                }

                &.parent {
                    > a {
                        &:after {
                            position: absolute;
                            left: auto;
                            right: 0;
                            top: 0;
                            @include fa-icon();
                            display: block;
                            content: $fa-var-chevron-down;
                            border: 0;
                            font-size: 14px;
                            color: $c-hkv-grey;
                            transition: transform .15s ease-out;
                            width: 30px;
                            height: 30px;
                            text-align: center;
                            line-height: 30px;
                            margin-top: 0;
                        }
                    }

                    &.active {
                        > a {
                            color: $c-hkv-blue;

                            &:after {
                                transform: rotate(180deg);
                            }
                        }

                        > ul {
                            display: block;
                        }

                    }
                }
            }
        }
    }

    &-callouts {
        display: none;

        .callout {
            display: block;
            width: 100%;
            margin-bottom: 23px;
            border: 1px solid $c-hkv-grey-light;
            position: relative;

            img {
                width: 100%;
                height: auto;
            }

            .tag {
                background: rgba(0, 0, 0, 0.5);
                padding: 5px 10px;
                position: absolute;
                bottom: 36px;
                left: 0;
                color: $c-white;
                text-transform: uppercase;
            }

            .title {
                display: block;
                background: $c-hkv-blue;
                color: $c-white;
                padding: 6px 10px;
                font-weight: bold;
                font-size: $f-size-xl;

                &:after {
                    @include fa-icon();
                    display: block;
                    content: $fa-var-chevron-right;
                    border: 0;
                    font-size: 14px;
                    color: $c-white;
                    float: right;
                    margin-top: 4px;
                }
            }
        }

        &-top {
            .callout {
                display: block;
                width: 100%;
                margin-bottom: 23px;
                border: 1px solid $c-hkv-grey-light;

                img {
                    max-width: 200px;
                    margin: 10px auto;
                }

                .title {
                    &:after {
                        float: right;
                    }
                }
            }
        }
    }

    &-usp {
        background: $c-hkv-blue;
        padding: 30px;
        line-height: 30px;
        color: $c-white;
        font-size: $f-size-l;
        margin-bottom: 30px;

        i {
            color: $c-hkv-red;
            font-size: 18px;
        }

        .title {
            font-weight: bold;
            font-size: $f-size-xxl;
            margin-bottom: 5px;
        }
    }

    &-newsletter {
        background: $c-hkv-blue-dark;
        padding: 30px 20px 20px;
        text-align: center;
        margin-bottom: 20px;

        .title {
            font-weight: bold;
            text-transform: uppercase;
            font-size: $f-size-xl;
            color: $c-white;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 25px;
            color: $c-white;
        }

        .block-content {
            position: relative;
        }

        input {
            height: 42px;
            border: 0;
            border-radius: 21px;
            line-height: 42px;
            padding: 0 50px 0 15px;
            width: 100%;
        }

        .button {
            padding: 0;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 2px;
            background: $c-hkv-green;

            span {
                display: none;
            }

            &:after {
                @include fa-icon();
                display: block;
                content: $fa-var-chevron-right;
                border: 0;
                font-size: 14px;
                color: $c-white;
                transition: transform .15s ease-out;
                height: 42px;
                width: 42px;
                text-align: center;
                line-height: 42px;
            }
        }

    }

    &-sale {
        margin-bottom: 20px;

        .title {
            font-size: $f-size-3xl;
            padding: 10px 0 20px;
            font-weight: bold;
            color: $c-hkv-blue;
        }
    }

    &-reviews {
        position: relative;

        .title {
            font-size: $f-size-3xl;
            padding: 10px 0 20px;
            font-weight: bold;
            color: $c-hkv-blue;
        }

        .review {
            padding: 15px;
            float: left;
            font-size: $f-size-l;
            color: $c-hkv-blue-dark;
            font-style: italic;

            .inner {
                border: 1px solid $c-hkv-grey-light;
                padding: 20px;
            }

            .stars {
                position: relative;
                width: 110px;
                height: 25px;
                margin-bottom: 5px;
                font: 0/0 a;

                &:before,
                &:after {
                    @include fa-icon();
                    content: '\f005 \f005 \f005 \f005 \f005';
                    letter-spacing: 3px;
                    color: $c-hkv-green;
                    font-size: 20px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                &:before {
                    color: $c-hkv-grey-light;
                }

                &:after {
                    height: 25px;
                    overflow: hidden;
                }

                &-5:after {
                    width: 110px;
                }

                &-4:after {
                    width: 84px;
                }

                &-3:after {
                    width: 64px;
                }

                &-2:after {
                    width: 42px;
                }

                &-1:after {
                    width: 20px;
                }

                &-0s:after {
                    width: 0;
                }
            }

            .v-card {
                padding: 20px 0 10px;

                .picture {
                    float: left;
                    margin-right: 20px;
                }

                .company {
                    font-style: italic;
                }
            }
        }

        .inner {
            position: relative;
        }

        &__control-left,
        &__control-right {
            position: absolute;
            height: 30px;
            width: 30px;
            background: $c-black;
            z-index: 99;
            border: 0;
            transition: $default-transition-duration;
            top: calc(50% - 15px);
            border-radius: 3px;

            i.fa {
                font-size: 18px;
                height: 30px;
                width: 30px;
                line-height: 32px;
                text-align: center;
                transition: $default-transition-duration;
                color: $c-white;
            }
        }

        &__control-left {
            left: -5px;
        }

        &__control-right {
            right: -5px;
        }

    }

    &-brands {
        background: $c-hkv-grey-light;
        position: relative;
        margin-bottom: 40px;

        .home-container {
            position: relative;
        }

        .control-left,
        .control-right {
            position: absolute;
            height: 30px;
            width: 30px;
            background: $c-black;
            z-index: 99;
            border: 0;
            transition: $default-transition-duration;
            top: calc(50% - 15px);
            border-radius: 3px;

            .fa {
                font-size: 18px;
                height: 30px;
                width: 30px;
                line-height: 32px;
                text-align: center;
                transition: $default-transition-duration;
                color: $c-white;
            }
        }

        .control-left {
            left: 0;
        }

        .control-right {
            right: 0;
        }

        .item {
            width: 150px;
            height: 120px;
            padding: 20px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &-content {
        display: none;
    }

}

@include bp(min-width, $bp-small + 1) {
    .main-slider {
        .item {
            height: auto;

            img {
                height: auto;
                width: 100%;
            }
        }
    }
}

@include bp(min-width, $bp-medium + 1) {
    .main-slider {
        position: relative;

        &__control-left {
            left: 0;
        }

        &__control-right {
            right: 0;
        }

        .item {
            height: 389px;
            text-align: right;

            img {
                height: 389px;
                width: auto;
            }

            .content {
                width: 360px;
                left: 0;
                bottom: 50px;
                margin: 0;
                padding-right: 20px;

                h2, h3, h4 {
                    font-size: 26px;
                }

                p {
                    font-size: 20px;
                    margin-bottom: 10px;
                }

                .button {
                    float: right;
                    font-size: $f-size-l;
                }
            }
        }
    }

    .home {
        &-container {
            max-width: 100%;
            width: $max-content-width;
            margin: 0 auto;

            &:after {
                @include clearfix();
            }
        }

        &-top {
            width: 100%;

            &:after {
                @include clearfix();
            }
        }

        &-slider {
            float: right;
            width: calc(100% - 240px);
            margin: 20px 0 0;
        }

        &-menu {
            float: left;
            width: 220px;
            padding: 0;
            margin-top: 20px;

            #nav-home {
                a.level0,
                a {
                    line-height: 29px;
                }

                li {
                    &.parent {
                        > a {
                            &:after {
                                content: $fa-var-chevron-right;
                            }
                        }

                        > ul.level1 {
                            position: absolute;
                            left: 100%;
                            background: $c-hkv-blue-light;
                            width: 250px;
                            z-index: 2;
                        }

                        &.nav-4 {
                            > ul {
                                position: absolute;
                                left: 100%;
                                background: $c-hkv-blue-light;
                                width: 250px;
                            }
                        }

                        &:hover {
                            position: relative;

                            > a {
                                color: $c-hkv-blue;
                                background: $c-hkv-blue-light;
                            }

                            > ul {
                                display: block;
                                top: 0;
                            }

                        }
                    }
                }
            }
        }

        &-callouts {
            display: block;

            &:after {
                @include clearfix();
            }

            &-second {
                margin-top: 10px;
                clear: both;
                width: 100%;
                float: left;

                .callout {
                    width: calc(50% - 15px);
                    float: left;
                    margin-right: 30px;

                    &:nth-child(even) {
                        margin-right: 0;
                    }
                }
            }

            &-top {
                margin-top: 20px;
                clear: right;

                .callout {
                    float: left;
                    width: calc(50% - 10px);

                    img {
                        width: auto;
                        height: 130px;
                    }

                    &:last-child {
                        float: right;
                    }
                }
            }
        }

        &-usp {
            display: none;
        }

        &-newsletter {
            .left {
                float: left;
                width: 50%;
                text-align: right;
                padding-right: 40px;
            }

            p {
                font-size: $f-size-xl;
                margin-bottom: 0;
            }

            .title {
                font-size: $f-size-xxl;
                margin-bottom: 0;
                margin-top: 8px;
            }

            .block-subscribe {
                float: right;
                width: 50%;
                padding-right: 5%;
                padding-top: 3px;

                input {
                    padding: 0 50px 0 15px;
                }

                .button {
                    border-radius: 21px;
                    padding-left: 20px;

                    span {
                        display: inline;
                        line-height: 40px;
                    }

                    &:after {
                        float: right;
                    }
                }

            }

        }

        &-sale {

        }

        &-reviews {

        }

        &-brands {

        }

        &-content {
            display: block;
            margin-bottom: 20px;

            p {
                margin-bottom: 15px;
                color: $c-black;
            }

            a {
                text-decoration: underline;
            }

        }

    }
}

@include bp(min-width, $bp-xlarge + 1) {
    .home {
        &-top {
            width: calc(100% - 185px);
            float: left;
        }

        &-slider {

        }

        &-menu {

        }

        &-callouts {
            &-second {
                .callout {
                    width: calc(25% - 23px);

                    &:nth-child(even) {
                        margin-right: 30px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &-top {
                float: right;
                width: 165px;

                .callout {
                    float: none;
                    width: 100%;
                    margin-bottom: 20px;

                    img {
                        height: 93px;
                        margin: 27px auto;
                    }
                }
            }
        }

        &-reviews {

        }

    }
}

// ----------------------------------------------
// Home slider
@media only screen and (min-width: 480px) and (max-width: 770px) {
    .cms-home {
        .page {
            padding-top: 50px;

            #header-search {
                display: none;

                &.active {
                    display: block;
                }
            }

            .account-cart-wrapper {
                width: unset;

                .search-toggle {
                    display: block;
                    margin-right: 12px;
                }
            }

            .main-slider .item {
                min-height: 260px;
                max-height: 290px;

                img {
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
}