.attribute-matrix {
    tr {
        border: 0;
    }

    th {
        background: transparent;
        padding: 3px 10px 0 10px;
    }

    td {
        padding: 0 10px 5px 10px;
    }

    input {
        width: 30px;
        text-align: center;
        padding: 2px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}