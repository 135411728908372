
.brand-overview-title {
    float: left;
    margin: 8px 0;
}

.search-brand {
    float: right;
    display: inline-block;
    position: relative;
    input {
        height: 40px;
        width: 280px;
        border: 1px solid #ddd;
    }
    &:after {
        content: "\f002";
        font-family: FontAwesome;
        position:absolute;
        right: 20px;
        top: 8px;
        font-size: 18px;
    }
}

.brand-list {
    @include clearfix();
	.item {
		@include span-columns(1);
        min-height: 200px;
        margin-top: 20px;
        &:nth-child(1), &:nth-child(2), &:nth-child(3),&:nth-child(4) {
            margin-top:10px;
        }
        &:nth-child(4n) {
            margin-right:0;
            &:after {
                @include clearfix();
            }
        }
        .icon-brand, .icon-brand--hover {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
        .icon-brand {
            display: block;
            &--wrapper {
                border: 1px dashed #ddd;
                height: 140px;
                width: 100%;
                position: relative;
            }
            &--hover {
                display: none;
            }
        }
        &:hover, &:focus {
            text-decoration: none;
            background: #ddd;
            transition: .4s all ease;
            .icon-brand {
                display:none;
                &--hover {
                    display:block;
                }
            }
        }
        &:after {
            content: "";
            width:100%;
            height:1px;
            display:block;
            background: transparent;
            border-bottom: 1px dashed #ddd;
        }
		@include bp(min-width, $bp-xsmall + 1) {
			@include span-columns(2);
			min-height: 280px;
		}
		@include bp(min-width, $bp-medium + 1) {
			@include span-columns(3);
		}
    }
    h2 {
        margin-top: 15px;
        font-size: 14px;
        margin-left: 20px;
        text-transform: none;
    }
    p {
        margin: 20px;
        color: $c-text;
    }

}