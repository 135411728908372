.product-label {
    position: absolute;

    &.sale {
        background: $c-hkv-red;
        color: $c-white;
        bottom: 0;
        right: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border-bottom: 2px solid $c-hkv-red-dark;
        font-weight: bold;
        text-align: center;
        line-height: 40px;

        &.green {
            background: $c-hkv-green;
            border-bottom: 2px solid $c-hkv-green-dark;
        }
    }

    &.hkv-choice {
        background: $c-hkv-blue;
        color: $c-white;
        text-transform: uppercase;
        font-size: 9px;
        height: 20px;
        line-height: 20px;
        padding: 0 8px 0 10px;
        font-weight: bold;
        top: 0;
        left: -10px;

        &:after,
        &:before {
            left: 100%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-left-color: $c-hkv-blue;
            border-width: 8px;
            margin-left: -8px;
        }

        &:after {
            transform: rotate(-135deg);
            top: -8px;
        }

        &:before {
            transform: rotate(135deg);
            bottom: -8px;
        }
    }
}

@include bp(min-width, $bp-small + 1) {
    .product-label {
        position: absolute;

        &.sale {
            right: 0;
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: $f-size-xl;
        }

        &.hkv-choice {
            font-size: 12px;
            height: 28px;
            line-height: 28px;
            padding: 0 10px 0 12px;
            top: 0;
            left: -20px;

            &:after,
            &:before {
                border-width: 10px;
                margin-left: -10px;
            }

            &:after {
                top: -10px;
            }

            &:before {
                bottom: -10px;
            }
        }
    }
}

