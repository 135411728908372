@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url('../../default/images/compiled/' + $sprite-image);
}

@mixin sprite-background-size($sprite) {
  background-size: nth($sprite, 7) nth($sprite, 8);
}

@mixin sprite-retina($sprite, $sprite2x) {
  @include sprite($sprite);

  @media (-webkit-min-device-pixel-ratio: 2) {
    @include sprite-image($sprite2x);
    @include sprite-background-size($sprite);
  }
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}