.news-overview-title {
    float: left;
    margin: 8px 0;
    width: 100%;
    font-weight: bold;
}

div.news-item {
    position: relative;
    @include span-columns(1);
    border: 1px dashed #dddddd;
    background: #ffffff;
    height: 340px;
    margin-top: 20px;

    @include bp(min-width, $bp-xsmall + 1) {
        min-height: 350px;
        float: left;
        width: calc((100% / 2) - 10px);

        &:nth-child(2n+2) {
            margin-left: 20px;
        }
    }

    @include bp(min-width, $bp-medium + 1) {
        width: calc((100% / 3) - 10px);

        &:nth-child(2n+2) {
            margin-left: 0;
        }

        &:nth-child(3n+1) {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    .news_image {
        overflow: hidden;
        height: 120px;
        position: relative;

        img {
            width: 100%;
            opacity: .8;
            transition: opacity .2s ease;
            position: absolute;
            bottom: 0;
            height: 100%;
            object-fit: contain;
        }
    }
    .news_content {
        padding: 25px;
        h5 a {
            font-family: $font-family-serif;
            font-size: 14px;
            font-weight: bold;
            text-transform: none;
            color: #333333;
        }

        .news_content__link {
            font-weight: bold;
        }
    }
    &:hover, &:focus {
        border: 1px dashed darken(#dddddd, 20%);

        .news_image img {
            opacity: 1;
        }

        .news_content__link {
            color: $c-hkv-orange !important;
        }
    }
}

div.news-item:nth-of-type(1) {
    background: #dddddd;
    width: 100%;
    min-height: 340px;
    height: auto;
    margin-bottom: 20px;
    margin-top: 14px;
    padding: 25px;
    margin-left: 0;

    .news_image {
        width: 100%;
        overflow: hidden;
        height: 250px;
        padding: 12px;
        background: #ffffff;
        border: 1px dashed darken(#dddddd, 10%);

        @include bp(min-width, $bp-medium + 1) {
            width: 40%;
            height: 340px;
            float: right;
        }

        img {
            width: 100%;
            opacity: .8;
            transition: opacity .2s ease;
            height: auto;
            position: relative;
        }
    }
    .news_content {
        width: 100%;
        float: left;

        @include bp(min-width, $bp-medium + 1) {
            width: 60%;
        }

        span.date {
            display: block;
            border-bottom: 1px dashed #ffffff;
            font-size: 14px;
            padding-bottom: 8px;
        }
        h5 a {
            margin-top: 6px;
            font-size: 24px;
            display: block;
            width: 100%;
        }
        .description {
            margin-top: 30px;
        }
        .news_content__link {
            bottom: 40px;

            @include bp(min-width, $bp-large + 1) {
                position: absolute;
                bottom: 20px;
            }
        }
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: transparent;
        border-bottom: 1px dashed #dddddd;
        position: absolute;
        bottom: -20px;
        left: 0;
    }
}

.news-article {
    margin-top: 20px;

    .title {
        font-size: 24px;
        font-family: $font-family-serif;
        font-weight: bold;
        text-transform: none;
        color: #333333;
    }
    .news-article__image {

        &-wrapper {
            margin-left: 15px;
            margin-bottom: 15px;
            width: 50%;
            float: right;
        }
        overflow: hidden;
        padding: 15px;
        border: 1px dashed #dddddd;
        img {
            width: 100%;
        }
    }
    .description {
        margin-top: 30px;
        line-height: 27px;
        font-size: 12px;
    }
    .item-article-share {
        width: 100%;
        border-top: 1px dashed #dddddd;
        padding-top: 25px;
        margin-top: 40px;

        a {
            float: right;
            border-radius: 3px;
            background-color: #333333;
            width: 36px;
            height: 36px;
            display: inline-block;
            margin-right: 5px;
            border-top: 1px solid #727675;
            border-bottom: 1px solid #727675;

            &:hover, &:focus {
                text-decoration: none;
            }

            &.facebook {
                &:hover, &:focus {
                    background: #3b5a9a;
                    border-color: lighten(#3b5a9a, 20%);
                }
                span.icon {
                    @include sprite-retina($sprite-default-core-social-icon-facebook, $sprite-default-core-2x-social-icon-facebook);
                    display: block;
                }
            }
            &.twitter {
                &:hover, &:focus {
                    background: #1aa9e1;
                    border-color: lighten(#1aa9e1, 20%);
                }
                span.icon {
                    @include sprite-retina($sprite-default-core-social-icon-twitter, $sprite-default-core-2x-social-icon-twitter);
                    display: block;
                }
            }
            &.rss {
                &:hover, &:focus {
                    background: #ff8201;
                    border-color: lighten(#ff8201, 20%);
                }
                span.icon {
                    @include sprite-retina($sprite-default-core-social-icon-rss, $sprite-default-core-2x-social-icon-rss);
                    display: block;
                }
            }
            &.email {
                &:hover, &:focus {
                    background: #c7392c;
                    border-color: lighten(#c7392c, 20%);
                }
                span.icon {
                    @include sprite-retina($sprite-default-core-social-icon-enveloppe, $sprite-default-core-2x-social-icon-enveloppe);
                    display: block;
                }
            }
            &.linkedin {
                &:hover, &:focus {
                    background: #0080b1;
                    border-color: lighten(#0080b1, 20%);
                }
                span.icon {
                    @include sprite-retina($sprite-default-core-social-icon-linkedin, $sprite-default-core-2x-social-icon-linkedin);
                    display: block;
                }
            }
        }
    }
}