/**
 * @ Emico 2015
 * in opdracht voor HKV OCHTEN
 *
 * CONTENTS:

 * Unordered List
 *
 * Typography
 *
 * Grid
 */

/*------------------------------------*\
    Unordered List
\*------------------------------------*/

.cms__nav-list {
    border-radius: 6px;
    border: 1px dashed #ddd;
    padding: 10px 0;
    max-width:280px;
    li {
        margin: 0;
        padding: 0;
        a {
            color:#333;
            font-size: 14px;
            font-weight: bold;
            padding: 8px 0 8px 25px;
            display: block;
            &:hover, &:focus {
                background: #ddd;
                text-decoration: none;
            }
        }
    }
}
/*------------------------------------*\
    cms list with dropdown
\*------------------------------------*/

.cms__nav-list-withsubitems{
    border-radius: 6px;
    border: 1px dashed #ddd;
    padding: 10px 0;
    max-width:280px;
    h3 {
        width: 100%;
        cursor: pointer;
        position: relative;
        font-size: 14px;
        font-weight: bold;
        padding: 8px 0 8px 25px;
        &:hover, &:focus {
            background: #ddd;
            text-decoration: none;
        }
        &:after {
            content: "\f078";
            font-family: "FontAwesome";
            position: absolute;
            right: 10px;
            opacity: .7;
        }
        &.collapsed:after {
            content: "\f077";
            opacity: 1;
        }
    }
    ul li {
        padding: 8px 5px 8px 25px;
        a {
            color: $c-hkv-orange;
            font-size: 12px;
        }
    }
}

hr  {
    border:0;
    height: 0;
    border-bottom: 1px dashed #ddd;
    margin-top: 10px;
    margin-bottom: 10px;
}

.col-left .cms__nav-list {
    margin-top:20px;
    margin-right: 20px;
}


@include bp(min-width, $bp-large + 1) {
    .col-left .cms-list {
        margin-right: 20px;
    }
    .col-right .cms-list {
        margin-left: 20px;
    }
}


/*------------------------------------*\
    FAQ
\*------------------------------------*/


.faq-overview-container {
    .faq-box {
        @include span-columns(4);
        min-height: 200px;
        border-bottom:1px dashed #ddd;
        padding: 10px 20px;
        transition: .4s all ease;
        &:nth-child(3n) {
            margin-right: 0;
        }
        &:hover, &:focus {
            background: #ddd;
            a {
                color: $c-hkv-orange;
            }
        }


    }
}

/*------------------------------------*\
    Typography
\*------------------------------------*/

/* ============================================ *
 * Typography
 * ============================================ */

h1, .h1 {
    @include h1;
}

h2, .h2 {
    @include h2;
}

h3, .h3 {
    @include h3;
}

h4, .h4 {
    @include h4;
}

h5, .h5 {
    @include h5;
}

h6, .h6 {
    @include h6;
}




/*------------------------------------*\
    Grid
\*------------------------------------*/

@include bp(min-width, $bp-medium + 1) {
    .cms__col {
        &--1-of-3 {
            @include span-columns(4);
        }
        &--2-of-3 {
            @include span-columns(8);
        }
        &--1-of-2 {
            @include span-columns(6);
        }
    }
}

.cms__row {
    margin: 20px 0;
    &:after {
        @include clearfix();
    }
}

/*------------------------------------*\
    images CMS
\*------------------------------------*/

.img-responsive {
    max-width: 100%;
}

.cms__img {
    border-radius: 4px;
    border: 1px dashed #ddd;
    padding: 15px;
}

/*------------------------------------*\
    Button CMS
\*------------------------------------*/

.button-blue, .button-orange, .button-default {
    border-width: 0;
    margin: 7px 0;
    padding: 10px 40px;
    border-radius: 4px;
    font-size: 15px;
    font-weight:bold;
    font-family: $f-stack-special;
    transition: $default-transition-duration;
}

.button {
    &-blue {
        color: #fff;
        background: $c-hkv-blue;
        &:focus, &:hover {
            background: lighten($c-hkv-blue, 5%);
        }
    }
    &-orange {
        color: #fff;
        background: $c-hkv-orange;
        &:focus, &:hover {
            background: lighten($c-hkv-orange, 5%);
        }
    }
    &-default {
        color: #333;
        background: #ddd;
        &:focus, &:hover {
            background: lighten(#ddd, 5%);
        }
    }
    &-small {
        font-size: 14px;
    }
    &-large {
        font-size: 18px;
        text-transform: uppercase;
    }
}

/*------------------------------------*\
    Blockquote CMS
\*------------------------------------*/

blockquote {
    padding: 20px 20px;
    margin: 0 0;
    font-style: italic;
    border-left: 5px solid #ddd;
    font-weight: bold;
    p {
        margin: 0;
        font-size: 18px;
    }
}

/*------------------------------------*\
    list styled
\*------------------------------------*/

ul.cms__list-styled li {
    margin: 10px 0;
    list-style: none;
    &:before {
        content: "";
        width: 6px;
        height: 6px;
        display: inline-block;
        margin-right: 18px;
        border-radius: 50%;
        background: $c-hkv-orange;
    }
    ul li {
        &:before {
            content: "";
            width: 6px;
            height: 6px;
            border: 1px solid $c-hkv-orange;
            border-radius: 50%;
            display: inline-block;
            margin-right: 18px;
            background: transparent;
            margin-left: 28px;
        }
    }
}

/*------------------------------------*\
    Address
\*------------------------------------*/

address {
    line-height: 27px;
}