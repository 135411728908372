.catalog-product-view {
    .stock-info-popover {
        .fa {
            font-size: 20px;
            margin-left: 5px;
            color: $c-text;
        }
    }

    .super-attribute-option {
        position: relative;

        .stock-label {
            float: right;
            font-size: 10px;
        }

        .price-box {
            display: inline-block;
            margin: 0 0 4px 0;

            .price {
                font-size: 12px;
            }

            .special-price {
                padding-left: 4px;
                font-weight: bolder;
            }
        }
    }

    .add-stock-notifier{

        .product-info{
            margin-top: 20px;
            @include clearfix;
            .product-image{
                float: left;
                margin-right: 20px;
            }

            .product-info-right{
                float: left;
            }

            .product-options {
                @include clearfix;
                border: none;
                dt {
                    clear: left;
                    float: left;
                    margin: 0;
                    margin-right: 10px;
                }

                dd{
                    border: none;
                }
            }






        }

    }
}