
.cd-top {
    display: inline-block;
    height: 38px;
    width: 38px;
    position: fixed;
    bottom: 100px;
    right: 10px;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.8) no-repeat center 50%;
    border:4px solid $c-hkv-blue;
    border-radius: 2px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s 0s, visibility 0s .3s;
    &:after {
        content: '\f077';
        font-family: FontAwesome;
        position:absolute;
        color: $c-hkv-blue;
        top:-4px;
        font-size:24px;
        right:26px;
        z-index: 9999;
    }
}
.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
    transition: opacity .3s 0s, visibility 0s 0s;
}
.cd-top.cd-is-visible {
    visibility: visible;
    opacity: 1;
}
.cd-top.cd-fade-out {
    opacity: .5;
}
.no-touch .cd-top:hover {
    background-color: #fff;
    border: 4px solid $c-hkv-blue;
    opacity: 1;
}
@media only screen and (min-width: 768px) {
    .cd-top {
        right: 20px;
        bottom: 20px;
    }
}
@media only screen and (min-width: 1024px) {
    .cd-top {
        height: 60px;
        width: 60px;
        right: 30px;
        bottom: 30px;

        &:after {
            top:6px;
            right:37px;
        }
    }
}