@charset "UTF-8";
// __      ____      ____      __       ___ _ __ ___ (_) ___ ___        _ __ | |
// \ \ /\ / /\ \ /\ / /\ \ /\ / /      / _ \ !_ ! _ \| |/ __/ _ \      |  _ \| |
//  \ V  V /  \ V  V /  \ V  V /   _  |  __/ | | | | | | (_| (_) |  _  | | | | |
//   \_/\_/    \_/\_/    \_/\_/   (_)  \___|_| |_| |_|_|\___\___/  (_) |_| |_|_|

// =============================================
// Simple 12 column grid
// =============================================

.container {
    width: 100%;

    .row {
        position: relative;
        width: calc(100% + #{$grid-gutter});
        margin: 0 ($grid-gutter / 2 * -1);

        &:after {
            @include clearfix();
        }
    }
}

.hidden-sm {
    display: none;
}

.hidden-lg {

}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    margin: 0 ($grid-gutter / 2) $grid-gutter;
    float: left;
    width: calc(#{$grid-width} - #{$grid-gutter});

    img {
        max-width: 100%;
        height: auto;
    }
}

.col-sm-1 {
    width: calc((#{$grid-width} / 12) - #{$grid-gutter});
}

.col-sm-2 {
    width: calc((#{$grid-width} / 6) - #{$grid-gutter});
}

.col-sm-3 {
    width: calc((#{$grid-width} / 4) - #{$grid-gutter});
}

.col-sm-4 {
    width: calc((#{$grid-width} / 3) - #{$grid-gutter});
}

.col-sm-5 {
    width: calc((#{$grid-width} / (12 / 5)) - #{$grid-gutter});
}

.col-sm-6 {
    width: calc((#{$grid-width} / 2) - #{$grid-gutter});
}

.col-sm-7 {
    width: calc((#{$grid-width} / (12 / 7)) - #{$grid-gutter});
}

.col-sm-8 {
    width: calc((#{$grid-width} / (12 / 8)) - #{$grid-gutter});
}

.col-sm-9 {
    width: calc((#{$grid-width} / (12 / 9)) - #{$grid-gutter});
}

.col-sm-10 {
    width: calc((#{$grid-width} / (12 / 10)) - #{$grid-gutter});
}

.col-sm-11 {
    width: calc((#{$grid-width} / (12 / 11)) - #{$grid-gutter});
}

@include bp(min-width, $bp-large + 1) {
    .col-1 {
        width: calc((#{$grid-width} / 12) - #{$grid-gutter});
    }
    .col-2 {
        width: calc((#{$grid-width} / 6) - #{$grid-gutter});
    }
    .col-3 {
        width: calc((#{$grid-width} / 4) - #{$grid-gutter});
    }
    .col-4 {
        width: calc((#{$grid-width} / 3) - #{$grid-gutter});
    }
    .col-5 {
        width: calc((#{$grid-width} / (12 / 5)) - #{$grid-gutter});
    }
    .col-6 {
        width: calc((#{$grid-width} / 2) - #{$grid-gutter});
    }
    .col-7 {
        width: calc((#{$grid-width} / (12 / 7)) - #{$grid-gutter});
    }
    .col-8 {
        width: calc((#{$grid-width} / (12 / 8)) - #{$grid-gutter});
    }
    .col-9 {
        width: calc((#{$grid-width} / (12 / 9)) - #{$grid-gutter});
    }
    .col-10 {
        width: calc((#{$grid-width} / (12 / 10)) - #{$grid-gutter});
    }
    .col-11 {
        width: calc((#{$grid-width} / (12 / 11)) - #{$grid-gutter});
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11 {
        margin: 0 ($grid-gutter / 2) $grid-gutter;
        float: left;
    }

    .hidden-sm {
        display: block;
    }

    .hidden-lg {
        display: none;
    }
}