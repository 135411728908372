
@mixin border-radius($radius)
{
  border-radius: $radius;
}

@mixin opacity($opacity) {
  opacity: $opacity;
}

@mixin box-shadow($shadow)
{
  box-shadow: $shadow;
}

@mixin single-transition($style, $time)
{
  transition: $style, $time;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin span-columns($columns) {
  float: left;
  width: calc(100% / #{$columns});
}