// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-default-core-social-icon-enveloppe-name: 'sprite-default-core_social-icon-enveloppe';
$sprite-default-core-social-icon-enveloppe-x: 144px;
$sprite-default-core-social-icon-enveloppe-y: 0px;
$sprite-default-core-social-icon-enveloppe-offset-x: -144px;
$sprite-default-core-social-icon-enveloppe-offset-y: 0px;
$sprite-default-core-social-icon-enveloppe-width: 36px;
$sprite-default-core-social-icon-enveloppe-height: 36px;
$sprite-default-core-social-icon-enveloppe-total-width: 180px;
$sprite-default-core-social-icon-enveloppe-total-height: 180px;
$sprite-default-core-social-icon-enveloppe-image: 'sprite-default-core-1561385307481.png';
$sprite-default-core-social-icon-enveloppe: (144px, 0px, -144px, 0px, 36px, 36px, 180px, 180px, 'sprite-default-core-1561385307481.png', 'sprite-default-core_social-icon-enveloppe', );
$sprite-default-core-social-icon-facebook-name: 'sprite-default-core_social-icon-facebook';
$sprite-default-core-social-icon-facebook-x: 108px;
$sprite-default-core-social-icon-facebook-y: 36px;
$sprite-default-core-social-icon-facebook-offset-x: -108px;
$sprite-default-core-social-icon-facebook-offset-y: -36px;
$sprite-default-core-social-icon-facebook-width: 36px;
$sprite-default-core-social-icon-facebook-height: 36px;
$sprite-default-core-social-icon-facebook-total-width: 180px;
$sprite-default-core-social-icon-facebook-total-height: 180px;
$sprite-default-core-social-icon-facebook-image: 'sprite-default-core-1561385307481.png';
$sprite-default-core-social-icon-facebook: (108px, 36px, -108px, -36px, 36px, 36px, 180px, 180px, 'sprite-default-core-1561385307481.png', 'sprite-default-core_social-icon-facebook', );
$sprite-default-core-social-icon-linkedin-name: 'sprite-default-core_social-icon-linkedin';
$sprite-default-core-social-icon-linkedin-x: 72px;
$sprite-default-core-social-icon-linkedin-y: 72px;
$sprite-default-core-social-icon-linkedin-offset-x: -72px;
$sprite-default-core-social-icon-linkedin-offset-y: -72px;
$sprite-default-core-social-icon-linkedin-width: 36px;
$sprite-default-core-social-icon-linkedin-height: 36px;
$sprite-default-core-social-icon-linkedin-total-width: 180px;
$sprite-default-core-social-icon-linkedin-total-height: 180px;
$sprite-default-core-social-icon-linkedin-image: 'sprite-default-core-1561385307481.png';
$sprite-default-core-social-icon-linkedin: (72px, 72px, -72px, -72px, 36px, 36px, 180px, 180px, 'sprite-default-core-1561385307481.png', 'sprite-default-core_social-icon-linkedin', );
$sprite-default-core-social-icon-rss-name: 'sprite-default-core_social-icon-rss';
$sprite-default-core-social-icon-rss-x: 36px;
$sprite-default-core-social-icon-rss-y: 108px;
$sprite-default-core-social-icon-rss-offset-x: -36px;
$sprite-default-core-social-icon-rss-offset-y: -108px;
$sprite-default-core-social-icon-rss-width: 36px;
$sprite-default-core-social-icon-rss-height: 36px;
$sprite-default-core-social-icon-rss-total-width: 180px;
$sprite-default-core-social-icon-rss-total-height: 180px;
$sprite-default-core-social-icon-rss-image: 'sprite-default-core-1561385307481.png';
$sprite-default-core-social-icon-rss: (36px, 108px, -36px, -108px, 36px, 36px, 180px, 180px, 'sprite-default-core-1561385307481.png', 'sprite-default-core_social-icon-rss', );
$sprite-default-core-social-icon-twitter-name: 'sprite-default-core_social-icon-twitter';
$sprite-default-core-social-icon-twitter-x: 0px;
$sprite-default-core-social-icon-twitter-y: 144px;
$sprite-default-core-social-icon-twitter-offset-x: 0px;
$sprite-default-core-social-icon-twitter-offset-y: -144px;
$sprite-default-core-social-icon-twitter-width: 36px;
$sprite-default-core-social-icon-twitter-height: 36px;
$sprite-default-core-social-icon-twitter-total-width: 180px;
$sprite-default-core-social-icon-twitter-total-height: 180px;
$sprite-default-core-social-icon-twitter-image: 'sprite-default-core-1561385307481.png';
$sprite-default-core-social-icon-twitter: (0px, 144px, 0px, -144px, 36px, 36px, 180px, 180px, 'sprite-default-core-1561385307481.png', 'sprite-default-core_social-icon-twitter', );
$spritesheet-width: 180px;
$spritesheet-height: 180px;
$spritesheet-image: 'sprite-default-core-1561385307481.png';
$spritesheet-sprites: ($sprite-default-core-social-icon-enveloppe, $sprite-default-core-social-icon-facebook, $sprite-default-core-social-icon-linkedin, $sprite-default-core-social-icon-rss, $sprite-default-core-social-icon-twitter, );
$spritesheet: (180px, 180px, 'sprite-default-core-1561385307481.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
