/* ============================================ *
 * Checkout - Layout
 * ============================================ */

.checkout-cart-index {
    .main {
        padding-bottom: 30px;
    }
    .page-title {
        font-weight: 900;
        text-transform: uppercase;
    }
}

.cart-table {
    float: left;
    width: 100%;
}

.cart .button {
    white-space: normal;
}

.cart-totals-wrapper {
    float: right;
    margin-top: 20px;

    .payment-methodes {
        width: 100%;
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        span {
            margin: 0 10px 0 10px;
        }
    }
}

.cart-totals {

}

.cart-forms .giftcard,
.cart-forms .shipping {
    padding: $box-spacing;
    background-color: $c-module-background;
    border: 1px solid $c-module-border;
}

.cart-table,
.cart-totals,
.cart-forms .discount,
.cart-forms .giftcard,
.cart-forms .shipping {
}

.checkout-cart-index {
    #postcode {
        width: 100%;
    }
}

@mixin oneColumnCartItems {
    .cart-table {
        float: none;
        width: 100%;
    }

    .cart-forms {
        float: left;
        padding-right: $box-spacing;
        padding-left: 0;
    }

    .cart-forms,
    .cart-totals-wrapper {
        width: 50%;
    }
}

.display-both-prices {
    @include oneColumnCartItems;
}

@include bp(max-width, $bp-large) {
    .display-single-price {
        @include oneColumnCartItems;
    }
}

@include bp(max-width, 599px) {
    .product-cart-sku {
        display: none;
    }

    .display-both-prices,
    .display-single-price {
        .cart-forms,
        .cart-totals-wrapper,
        .crosssell {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
            float: none;
            width: 100%;
        }
    }
}

.display-single-price {
    &:after {
        @include clearfix;
    }
}

/* ============================================ *
 * Checkout - Cart
 * ============================================ */

.cart .page-title {
    margin-bottom: 15px;

    &:after {
        @include clearfix;
    }
    h1 {
        border-bottom: none;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 33px;
    }
}

.checkout-types {
    text-align: right;
    width: 100%;

    .btn-checkout {
        float: none;
        display: block;
        width: 100%;
        padding: 15px 0;
        font-size: 16px;
        text-transform: uppercase;
    }

    li {
        &:after {
            @include clearfix;
        }
        vertical-align: top;
        margin: 0 0 5px 5px;

        img {
            display: inline;
            vertical-align: top;
        }
    }
    li:first-child {
        margin-left: 0;
    }
    /* We always want this shipping method to display on its own line */
    .method-checkout-cart-methods-multishipping {
        display: block;
    }

    &.top {
        li {
            display: inline-block;
        }

        .bml_button {
            display: inline-block;
            vertical-align: top;

            img {
                display: block;
            }
        }

        .paypal-logo .paypal-or {
            margin-top: 5px;
        }
    }

    &.bottom,
    &.minicart {
        .paypal-logo {
            a {
                display: block;
            }
        }
        .paypal-or {
            margin: 0px;
            display: block;
            text-align: center;
        }
    }
}

@include bp(min-width, 741px) {
    .checkout-types.bottom {
        .paypal-or {
            text-align: right;
            padding-right: 70px;
        }
    }
}

.cart-totals .checkout-types .btn-checkout {
    margin-bottom: 7px;
    border-radius: 3px;
}

@include bp(max-width, 740px) {
    .checkout-types {
        float: none;
        text-align: center;

        &.bottom,
        &.minicart {
            .paypal-or {
                width: auto;
                float: none;
            }
        }
    }

    .checkout-types li {
        float: none;
        width: 100%;
        margin-left: 0;
    }
}

@include bp(max-width, $bp-small) {
    .btn-checkout {
        width: 100%;
    }
}

.cart-table {
    border: 1px solid #bfbfbf;
    @include border-radius(4px);

    th,
    td,
    tbody td {
        // Adding tbody for extra selector weight
        border-bottom: none;
        vertical-align: top;
    }

    h2 {
        color: $c-h2;
        font-size: $f-size;
        font-weight: 600;
        margin-bottom: 0;
    }

    thead th,
    tbody td {
        background-color: transparent;
        font-size: 14px;
        text-transform: none;
        color: #333333;
        vertical-align: middle;
    }

    thead th {
        padding: 14px 25px;
        font-weight: bold;
    }
    .product-name {
        font-size: 14px;
        float: left;
        padding: 0 14px;
        text-transform: none;
        max-width: 190px;
        a {
            font-weight: normal;
        }
    }
    .item-options {
        margin-top: 2px;
        color: #7e828e;
    }

    .product-cart-actions {
        padding-left: 15px;
    }

    tr {
        border-bottom: 1px solid $c-table-border;
        &.has-matrix {
            border-bottom: 0;
        }
    }

    tfoot {
        tr {
            background: none;
        }

        tr > td:after {
            @include clearfix;
        }
    }

    span.or {
        font-size: 9px;
        padding: 0 5px;
        text-transform: uppercase;
        font-family: $f-stack-special;
    }

    .product-cart-info .btn-remove,
    .product-cart-image .cart-links,
    .product-cart-actions .button {
        display: none;
    }

    .product-cart-image {
        padding-left: 0;
        padding-right: 0;

        .product-image {
            border-width: 0;
            max-width: 120px !important;
            margin-right: 0;
            padding-right: 0;
            float: left;
        }

        a.cart-edit {
            display: none;
        }
    }

    .product-cart-sku {
        font-family: $f-stack-serif;
        font-style: italic;
        font-size: $f-size-xs;
        padding: 0px 14px;
        float: left;

        .label {
            font-weight: 600;
        }
    }

    .btn-empty {
        float: left;
    }

    .product-cart-total,
    .product-cart-price {
        vertical-align: middle;
        text-align: center;
    }

    .cart-tax-total {
        position: relative;
        cursor: pointer;

        &:after {
            @include triangle(left, 6px, $c-text-primary);
            position: absolute;
            top: 3px;
            right: -11px;
        }

        &.cart-tax-total-expanded {
            &:after {
                @include triangle(down, 6px, $c-text-primary);
                right: -15px;
                top: 6px;
            }
        }
    }

    .cart-links > li {
        white-space: nowrap;
        line-height: 1.3;
        margin-bottom: 5px;
        min-width: 65px;
        font-size: $f-size-s;

        > a {
            display: block;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .product-cart-actions {
        text-align: center;
        vertical-align: middle;

        .qty {
            height: 30px;
            border-color: $c-input-border;
            border-radius: 0;
            text-align: center;
            width: 3.2em;
        }

        .button, .button:hover, .button:focus {
            margin-left: 5px;
            border-bottom-width: 0;
            span {
                display: inline-block;
                @include sprite($sprite-default-general-icon-refresh);
            }

        }
    }

    .item-options {
        padding-left: 15px;
        @include bp(max-width, 599px) {
            padding-left: 0;
        }
    }
}

@include bp(max-width, $bp-medium) {
    .cart-table {

        th {
            font-size: $f-size-xs;
        }

        th,
        td {
            padding: 7px 6px;
        }

        .product-cart-actions > li {
            white-space: inherit;
        }
    }
}

@include bp(max-width, 699px) {
    .display-both-prices {
        .cart-table {
            thead th.cart-total-head,
            td.product-cart-total {
                display: none;
            }
        }
    }
}

@include bp(max-width, 599px) {

    .cart-table {
        colgroup, thead {
            display: none;
        }

        tr {
            display: block;
            margin-bottom: 10px;
            padding-bottom: 10px;
            position: relative;
            width: 100%;

            &:after {
                @include clearfix;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        tfoot tr {
            padding-bottom: 0;
        }

        td {
            border: none;
            display: block;

            &[data-rwd-label] {
                padding-left: 15px;
                margin-bottom: 6px;

                .price {
                    font-weight: normal;
                }

                &:before {
                    content: attr(data-rwd-label) ":";
                    font-size: 15px;
                    padding-right: 5px;
                    color: #7e828e;
                }
            }

            &.product-cart-price {
                text-align: left;
            }
        }

        h2 {
            font-size: $f-size-xs;
        }

        .cart-links {
            padding-top: 5px;
            padding-right: 5px;

            > li {
                white-space: normal;
                text-align: center;

                > a {
                    padding: 2px 0px;
                }
            }
        }

        .cart-tax-info {
            font-style: italic;
            padding-left: $trim-small;
            font-size: $f-size-s;

            .price {
                font-size: $f-size-s;
            }
        }

        .product-cart-image {
            width: percentage(1/4);
            float: left;
            padding-bottom: 0;

            a.cart-edit {
                display: block;
                text-align: center;
                font-size: $f-size-xl;
                text-transform: uppercase;
            }
        }

        .product-cart-info,
        td[data-rwd-label] {
            float: right;
            padding-left: 0;
            width: percentage(3/4);
            .cart-price {
                font-size: 15px;
            }
        }

        .product-cart-actions,
        .product-cart-price {
            padding-bottom: 0;
            padding-top: 0;
            float: right;
        }

        .product-cart-remove,
        .product-cart-total,
        .product-cart-actions .cart-links {
            display: none;
        }

        .product-cart-image,
        .product-cart-info .btn-remove {
            display: block;
        }

        // This element will be displayed via JS once the related input is focused
        .product-cart-actions .button {
            display: none;
        }

        .product-cart-info {

            .btn-remove {
                float: right;
                margin: -4px 0px 2px 7px;
            }

            .product-cart-sku {
                margin-bottom: 5px;
            }
        }

        .product-cart-actions {
            text-align: left;

            .qty {
                margin-right: 7px;
            }
        }

        .price,
        .product-cart-price:before {
            font-weight: 500;
            font-size: $f-size-l;
            font-family: $f-stack-sans;
        }

        .cart-footer-actions {
            text-align: center;
            width: 100%;

            #empty_cart_button {
                float: right;
            }

            .btn-continue {
                float: left;
            }

            .btn-update,
            span.or {
                display: none;
            }

        }
    }

    .display-both-prices {
        .cart-table {
            td {
                &[data-rwd-tax-label] {
                    &:before {
                        content: attr(data-rwd-tax-label) ":";
                    }
                }
            }
        }
    }
}

/* ============================================ *
 * Checkout - Estimate Shipping and Tax
 * ============================================ */

.shipping {

    h2 {
        font-size: $f-size-xs;
        font-weight: bold;
        margin: 0 0 5px;
    }

    select {
        max-width: 100%;
        height: 30px;
        display: block;
        border: 1px solid $c-input-border;
    }

    select.validation-failed {
        border-color: $c-danger;
    }

    .shipping-desc {
        display: none;
    }

    .buttons-set {
        border: none;
        margin: 0;
        padding: 0;
    }

    .form-list {
        &:after {
            @include clearfix;
        }
        li {
            float: left;
            margin: 5px 2% 10px 0;
        }

        .shipping-country {
            width: 37%;
        }

        .shipping-region {
            width: 41%;
        }

        .shipping-postcode {
            margin-right: 0;
            width: 18%;

            input {
                margin-top: 4px;
            }
        }

        .input-box {
            padding-top: 0;
        }

        input {
            height: 30px;
            margin-top: 4px;
        }

        label {
            font-family: $f-stack-special;
            font-size: $f-size-s;
            font-weight: 400;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }

    .sp-methods {
        padding: 10px 0 0;
        text-align: left;

        dd {
            margin-bottom: 10px;
        }

        label {
            font-family: $f-stack-serif;
            font-size: $f-size-xs;
            font-style: italic;
            min-width: 100px;

            span {
                font-family: $f-stack-special;
                font-weight: bold;
                font-style: normal;
            }
        }
    }

    #co-shipping-method-form .buttons-set .button {
        float: left;
        margin-left: 0;
    }
    #co-shipping-method-form .sp-methods dd label {
        border: 1px solid $c-module-border;
        background-color: $c-module-background-hover;
        min-width: 220px;

        &:hover {
            background-color: shade($c-module-background, 10%)
        }
    }
}

@include bp(max-width, $bp-medium) {

    .shipping .shipping-form .form-list > li {
        width: 100%;
        float: none;

        label {
            display: block;
        }

        input,
        select {
            width: 100%;
        }
    }
}

// =============================================
// Totals
// =============================================

.cart .cart-totals {
    text-align: right;

    &:after {
        @include clearfix;
    }

    table {
        font-family: $f-stack-special;
        font-size: 17px;
        margin-bottom: 20px;
        width: 100%;
        min-width: 300px;

        tr {
            height: 35px;
        }

        td {
            padding: 2px 5px;
        }
        td:first-child {
            padding-right: 10px;
            min-width: 120px;
            float: left;
            &.a-right {
                text-align: left;
            }
        }
        tbody tr:last-child td,
        tbody tr:last-child th {

        }

        tbody tr td:first-child {
            font-weight: bold;
            font-size: 18px;
            color: #333333;
        }

        tfoot {
            td {
                font-size: 17px;
                padding: 2px 5px;
                vertical-align: top;
            }

            strong span,
            span.price {
                font-family: $f-stack-sans;

            }

            strong {
                font-weight: 400;
            }
        }
    }
}

@include bp(max-width, 600px) {

    .cart-totals {
        text-align: right;
    }
}

@include bp(max-width, $bp-medium) {

    .cart .cart-totals table tfoot td {
        font-size: $f-size-xxl;
    }
}

// =============================================
// Discount codes and gift cards
// =============================================

.discount-form:after,
#giftcard-form:after {
    @include clearfix;
}

#discount-coupon-form {
    margin-top: 20px;
    a {
        color: #333333;
        text-decoration: none;
        margin-bottom: 10px;
        display: block;
        font-weight: bold;
    }
    float: left;
    .discount {
        .field-wrapper {
            input {
                width: 200px;
                float: left;
                height: 37px;
            }
            .validation-advice {
                display: block;
                clear: both;
            }
            button.button-refresh {
                background-color: $c-hkv-orange;
                font-size: 14px;
                color: #ffffff;
                padding: 8px 15px;
                border-width: 0;
                float: left;
                text-transform: uppercase;
                font-weight: bold;
                margin-left: 5px;
                border-radius: 4px;

            }
        }
    }
}

.cart .giftcard {
    width: 100%;

    h2 {
        display: none;
    }

    label {
        font-family: $f-stack-special;
        font-size: $f-size-xs;
        font-weight: 400;
        text-align: left;
        text-transform: uppercase;
        min-width: 105px;
        display: inline-block;
        margin-right: 10px;
    }

    .field-wrapper {
        display: inline-block;
    }

    .validation-advice {
        display: inline;
    }

    .button-wrapper {
        display: inline-block;
        vertical-align: bottom;

        > button {
            float: left;
        }
    }

    .input-text {
        border-radius: 0;
        height: 30px;
        margin: 4px 10px 0 0;
        width: 190px;
    }
}

.cart .giftcard p {
    margin-bottom: $element-spacing;
}

.cart .giftcard .check-gc-status {
    float: left;
    padding: 0px;

    > span > span {
        font-size: $f-size;
        text-transform: none;
    }
}

/* ============================================ *
 * Checkout - Cart Cross sell
 * ============================================ */

.cart-footer {
    margin-top: 20px;
    .ekomi {
        @include bp(min-width, $bp-medium + 1) {
            @include span-columns(3);
        }
    }
    .crosssell {
        @include bp(min-width, $bp-medium + 1) {
            @include span-columns(1);
        }
    }
}

.order-comment-cart {
    clear: left;
    margin-left: 60px;
}

@include bp(max-width, $bp-small) {
    .order-comment-cart {
        display: none;
    }
}

@include bp(max-width, $bp-small) {
    .cart-table {
        .cart-tax-total {
            &:after {
                right: -9px;
            }
            &.cart-tax-total-expanded {
                &:after {
                    right: -13px;
                }
            }
        }
    }
}

.order-customer-reference {

    .input-box {
        width: 100% !important;
    }

    .form-list {

        .input-text {
            width: 100%;
        }

    }
}

.order-pdf-file {
    position: relative;
    .upload-progress {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
    }
}
