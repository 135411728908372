.general-product {
    position: relative;
    overflow: hidden;
    padding: 10px;
    border-bottom: 1px solid $c-hkv-grey-light;

    &:after {
        @include clearfix();
    }

    &:hover,
    &:focus {

    }

    &__image {
        position: relative;
        display: block;

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }

    &__name {
        font-family: $f-stack-sans;
        font-size: 14px;
        font-weight: bold;
        color: $c-hkv-blue;
        height: 35px;
        line-height: 17px;
        display: block;
        overflow: hidden;
    }

    .price-box {
        display: block;
        margin: 5px 0;

        .regular-price .price,
        .special-price .price {
            font-family: $f-stack-special;
            font-weight: bold;
            font-size: 18px;
            color: $c-text;
        }

        .old-price .price {
            font-weight: 400;
            font-size: 18px;
            color: $c-text;
        }
    }

    &__availability {
        &--instock {
            color: $c-hkv-green;
            font-size: 13px;

            > span:last-child {
                span span:before {
                    content: "\f017";
                    font-family: FontAwesome;
                    margin-right: 3px;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;
                    margin-left: 10px;
                }
            }
        }
    }

    .ratings {
        margin-top: 15px;
    }

    .button {
        @include button-primary();
    }
}

//One column product layout
@include bp(max-width, $bp-small) {
    .general-product {
        text-align: left;

        &__image {
            float: left;
            width: 90px;
            margin-right: 10px;
            padding: 20px 0;
        }

        &__information {
            float: right;
            width: calc(100% - 100px);
        }

        .button {
            padding: 3px 18px 2px;

            span {
                display: none;
            }

            i {
                font-size: $f-size-4xl;
            }
        }

        .prolabel {
            position: relative;
        }
    }
}

//Multiple column product layout
@include bp(min-width, $bp-small + 1) {
    .general-product {
        text-align: center;
        padding: 20px;
        border: 1px solid $c-hkv-grey-light;
        transition: border $default-transition-duration ease-out;

        &:hover {
            border-color: $c-hkv-grey;
        }

        &__information {
            padding-top: 20px;
        }

        &__name {
            font-size: $f-size-l;
            margin-bottom: 10px;
        }

        .price-box {
            margin: 10px 0;
        }

        .button {
            padding: 4px 20px 1px;

            i {
                font-size: $f-size-4xl;
                float: left;
                padding-right: 10px;
            }

            span {
                float: right;
                line-height: 30px;
            }
        }


    }
}