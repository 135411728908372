.nav-container {
    margin: auto;
    position: relative;
    text-align: left;
}

#responsivemenu {
    margin: 0;
}

ul.responsivemenu li.level0 {
    float: left;
    display: block;
    padding: 0 1px 0 0;

    &.right {
        float: right;
        padding: 0 0 0 2px;
    }

    a.level0 {
        display: block;
        outline: 0 none;
        text-decoration: none;
        font-weight: bold;
    }

    &.right a.level0 {
        padding: 0 10px 0 8px;
    }

    a.level0 span {
        display: block;
    }
}

.dropdown-container {
    display: none;
    position: absolute;
    left: 0;
    z-index: 100;
    width: 100%;

    &.right {
        right: 0px;
        left: auto;
    }
}

ul.responsivemenu li.left .dropdown-container.right {
    right: 3px;
}

.dropdown-container .dropdown {
    background: #ffffff;
    float: left;
    z-index: 20;
    border-top: none;
    width: 100%;

    p {
        margin: 0;
    }

    .col {
        padding: 10px;
        clear: both;
    }
}

.category-list {
    clear: both;
    float: left;
    width: 100%;
}

.dropdown-container {
    .sub-column {
        float: left;

        ul {
            float: left;
            width: 100%;
            margin-bottom: 5px;
        }
    }

    .dropdown {
        a {
            display: block;
            text-decoration: none;
            font-size: 12px;
            font-weight: normal;
            line-height: 15px;
            padding: 3px 0 3px 0;
            text-transform: none;
            float: left;
            width: 85%;
        }

        li.level3 a {
            font-size: 11px;
        }

        a.level1 {
            border-bottom: 1px solid #e5e5e5;
            color: #0d0247;
            text-transform: uppercase;
            font-weight: bold;
            padding-left: 0;
        }

        li.level1 ul li ul {
            margin-left: 10px;
        }

        a:hover {
            color: #d96708;
        }

        ul li a {
            padding-left: 10px;
        }
    }
}

.col2 .sub-column {
    width: 50%;
}

.col3 .sub-column {
    width: 33.3%;
}

.col4 .sub-column {
    width: 25%;
}

.col5 .sub-column {
    width: 20%;
}

.dropdown-container {
    table {
        width: 100%;
    }
    .top_content {
        margin-bottom: 10px;
    }
    .bottom_content {
        clear: both;
    }
    .category-thumbnail {
        margin-bottom: 5px;
    }
    .static-content {
        font-size: 11px;
    }
}

.col .col2 .half {
    width: 45%;
    float: left;
    font-size: 11px;
    margin-right: 10px;
}

.dropdown-container {
    .fieldset {
        margin-bottom: 10px;
        margin-top: 23px;
    }
    .form-list li.wide textarea {
        width: 345px;
        height: 97px;
    }
    .buttons-set {
        margin: 0;
    }
    .products-grid {
        background: none;
        border: none;
        li.item {
            width: 164px;
            text-align: center;
            padding-bottom: 10px;
        }
    }
    .widget .widget-title {
        padding-left: 15px;
        padding-top: 0;
    }
    .widget-products a {
        background: none;
        padding-left: 0;
    }
    .dropdown .products-grid li a {
        width: 100%;
        float: none;
    }
    .products-grid {
        .product-image {
            margin: 5px auto;
            float: none;
        }
        .ratings, .add-to-links {
            display: none;
        }
        .actions {
            position: inherit;
        }
    }
}

@media (min-width: 770px) {
    ul.responsivemenu li.level0:hover .dropdown-container {
        display: block;
        position: absolute;
        top: 13px;
        left: 0px;
        float: none !important;
        width: 100%;
    }
}

@media (max-width: 770px) {
    .nav-container {
        width: 100%;
        margin: 0;
    }

    #nav {
        width: 100%;

        ul.level0 {
            > li {
                > a {
                    padding-left: 30px;
                }

                > ul > li {
                    > a {
                        padding-left: 40px;
                    }
                }
            }
        }
    }

    ul.responsivemenu {
        width: 100%;
        max-height: calc(100vh - 68px);
        overflow-y: auto;

        li.level0 {
            float: none !important;
            display: block;
            clear: both;
            width: 100%;
            border-bottom: 1px solid rgba(115, 115, 115, 0.3);
        }

        li {
            &:last-child a {
                border: 0;
            }
            a {
                color: $c-hkv-blue;
            }

            .parent {
                &:after {
                    @include fa-icon();
                    content: $fa-var-chevron-down;
                    position: absolute;
                    right: 20px;
                    top: 10px;
                    color: $c-gray;
                }
            }

            &.active {
                > ul {
                    display: block;
                }

                .parent {
                    &:after {
                        content: $fa-var-chevron-up;
                    }
                }
            }
        }

    }

    #nav li a,
    #responsivemenu li a.level0 {
        display: block;
        float: none;
        padding: 0 20px;

    }

    .dropdown-container {
        width: 100% !important;
        position: relative;
        top: 0 !important;
    }

    .static-content {
        display: none;
    }

    .col2 .sub-column,
    .col3 .sub-column,
    .col4 .sub-column,
    .col5 .sub-column {
        width: 100%;
    }

    .sub-column > ul {
        display: block;
    }

    .level1 > ul {
        display: none;
    }

    li.active > {
        div.dropdown-container, ul {
            display: block;
        }
    }
}