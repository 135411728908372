// =============================================
// Custom variable overrides
// =============================================


// =============================================
// Typography
// =============================================

// Font Stacks
$f-stack-sans:  Arial, "Helvetica Neue", Helvetica, sans-serif;
$f-stack-serif:  $f-stack-sans;
$f-stack-special: $f-stack-sans;

$font-family-sans-serif:  $f-stack-sans;
$font-family-serif:       $f-stack-sans;

// =============================================
// Brand Colors
// =============================================

// Usually not used directly in Sass partials.
// Only used to define context-based color vars in this file.

$c-hkv-blue-dark: #1C2853;
$c-hkv-blue: #25346D;
$c-hkv-blue-light: #E8EAF2;

$c-hkv-grey: #7F8188;
$c-hkv-grey-light: $c-hkv-blue-light;

$c-hkv-red: #FF0000;
$c-hkv-red-dark: #DB0000;

$c-hkv-orange: #e95223;

$c-hkv-green: #00AB00;
$c-hkv-green-dark: #008E00;

$c-blue: $c-hkv-blue;
$c-green: $c-hkv-green;
$c-orange: $c-hkv-orange;
$c-red: $c-hkv-red;

$c-gray: $c-hkv-grey;
$c-gray-light: $c-hkv-grey-light;

$c-text-white: $c-white;

$c-primary: $c-hkv-blue-dark;
$c-secondary: $c-hkv-blue;
$c-tertiary: $c-hkv-grey;

$c-action: $c-secondary;

// Font Sizes

$f-size-2xl: 20px;
$f-size-3xl: 21px;
$f-size-4xl: 27px;


// Buttons

$c-button: $c-hkv-blue;
$c-button-hover: $c-hkv-blue-dark;
$c-button-active: $c-hkv-blue-dark;

// Secondary Buttons

$c-button-secondary: $c-hkv-grey;
$c-button-secondary-hover: darken($c-hkv-grey, 5%);
$c-button-secondary-active: darken($c-hkv-grey, 10%);

$c-button-subtle: $c-hkv-grey-light;

$c-input-border: $c-secondary;

$c-module-border-light: $c-hkv-blue-light;