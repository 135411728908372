.company_usp {
    border: 1px dashed lighten(#333, 40%);
    padding: 25px 35px;
    border-radius: 5px;
    h3 {
        color: $c-hkv-blue;
        font-weight: bold;
        font-size: 14px;
    }
    ul {
        list-style: none;
        line-height: 45px;
        padding-left: 10px;
        li {
            &:before {
                content: "\f046";
                font-family: FontAwesome;
                margin-right: 10px;
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
            }
        }
    }
}